var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-data-table-cards',{attrs:{"footer-props":{
    'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
      ? ''
      : _vm.$t('items_per_page'),
  },"headers":_vm.headers,"headers_small":_vm.headers_small,"title":_vm.$tc('boxes', 1),"data":_vm.items,"loading":_vm.loading,"server-items-length":_vm.total,"totalItems":_vm.total,"id":"dataSimple","items-per-page":15},scopedSlots:_vm._u([{key:"item_income",fn:function(item){return [_vm._v(" "+_vm._s(_vm.$n(_vm.income(item), "currency"))+" ")]}},{key:"item_actions",fn:function(item){return [_c('EditBoxesTimetable',{ref:("timetable-" + (item.id)),attrs:{"box_id":item.id,"studio_id":_vm.$store.state.auth.user.studio.id,"icons":"","hidden":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-row',{staticStyle:{"position":"relative !important","display":"flex","justify-content":"center","min-width":"100px"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")]),_c('div',{style:(_vm.$vuetify.breakpoint.xsOnly
                ? 'position: absolute; right: 9px'
                : 'position: absolute; right: 32px'),attrs:{"icon":""}},[_c('v-row',{staticStyle:{"margin-top":"-2px","height":"20px"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.order_by > 1),expression:"item.order_by > 1"}],staticStyle:{"height":"20px"},attrs:{"icon":""},on:{"click":function($event){return _vm.moveUp(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up-thick")])],1)],1),_c('v-row',{staticStyle:{"height":"20px"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.order_by < _vm.total),expression:"item.order_by < total"}],staticStyle:{"height":"20px"},attrs:{"icon":""},on:{"click":function($event){return _vm.moveDown(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-down-thick")])],1)],1)],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.reserva({
              reserva: { id: item.id },
              action: 'save',
            })}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-6px !important"},attrs:{"small":""}},[_vm._v("$appointments")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("boxes.reservar")))])])],1),_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.$router.push({
              name: 'boxView',
              params: { id: item.id },
            })}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-5px !important"},attrs:{"small":""}},[_vm._v("$eye")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("view", { name: _vm.$tc("box") })))])])],1),_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){_vm.$refs[("timetable-" + (item.id))].open()}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-5px !important"},attrs:{"small":""}},[_vm._v("$clock")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("timetable", { name: _vm.$tc("box") })))])])],1),_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.OPEN_FORM({ box: item, action: 'edit' })}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-5px !important"},attrs:{"small":""}},[_vm._v("$editprofile")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("edit", { name: _vm.$tc("box") })))])])],1),_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.delBox(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-6px !important"},attrs:{"small":""}},[_vm._v("$delete")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("delete", { name: _vm.$tc("box") })))])])],1)],1)],1)]}},{key:"item_price",fn:function(item){return [(item.price != null)?_c('span',[_vm._v(_vm._s(_vm.$n(item.price, "currency")))]):_c('span',[_vm._v("-")])]}},{key:"item_token_payment",fn:function(item){return [(item.token_payment != null)?_c('span',[_vm._v(_vm._s(_vm.$n(item.token_payment, "currency")))]):_c('span',[_vm._v("-")])]}},{key:"item_user",fn:function(item){return [(item.user != null)?_c('span',[_vm._v(_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name))]):_c('span',[_vm._v("-")])]}},{key:"item_status",fn:function(item){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.status != null)?_c('span',_vm._g(_vm._b({style:(_vm.boxesHoursDay(item.id).percentage >= 0.5
              ? _vm.boxesHoursDay(item.id).percentage >= 0.75
                ? _vm.boxesHoursDay(item.id).percentage == 1
                  ? 'color:red; cursor:pointer'
                  : 'color:orange; cursor:pointer'
                : 'color:yellow; cursor:pointer'
              : 'cursor:pointer')},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$n(_vm.boxesHoursDay(item.id).percentage, "percent"))+" / "+_vm._s(_vm.boxesHoursDay(item.id).hour)+"h ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$n(_vm.boxesHoursDay(item.id).percentage, "percent"))+" "+_vm._s(_vm.$t("ocupation"))+" / "+_vm._s(_vm.boxesHoursDay(item.id).hour)+" "+_vm._s(_vm.$t("hours_available"))+" ")])])]}},{key:"item_avatar",fn:function(item){return [_c('v-avatar',{staticClass:"ml-2",staticStyle:{"margin":"0 auto","font-size":"25px","text-transform":"uppercase","color":"#272727","cursor":"pointer"},attrs:{"color":"primary","size":40}},[_c('span',[_vm._v(_vm._s(item.order_by))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <base-data-table-cards
    :footer-props="{
      'items-per-page-text': $vuetify.breakpoint.smAndDown
        ? ''
        : $t('items_per_page'),
    }"
    :headers="headers"
    :headers_small="headers_small"
    :title="$tc('boxes', 1)"
    :data="items"
    :loading="loading"
    :server-items-length="total"
    :totalItems="total"
    id="dataSimple"
    :items-per-page="15"
  >
    <template v-slot:item_income="item">
      {{ $n(income(item), "currency") }}
    </template>
    <template v-slot:item_actions="item">
      <EditBoxesTimetable
        :box_id="item.id"
        :studio_id="$store.state.auth.user.studio.id"
        icons
        hidden
        :ref="`timetable-${item.id}`"
      />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-row
            style="
              position: relative !important;
              display: flex;
              justify-content: center;
              min-width: 100px;
            "
          >
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>

            <!--<v-btn
                    style="margin-top: -2px; position: absolute; right: 20px"
                    v-if="item_order_by == 1"
                    icon
                    @click="moveDown(item)"
                    ><v-icon small>mdi-arrow-down-thick</v-icon></v-btn
                  >

                  <v-btn
                    style="margin-top: -2px; position: absolute; right: 20px"
                    v-else-if="item.order_by == totalBoxes"
                    icon
                    @click="moveUp(item)"
                    ><v-icon small>mdi-arrow-up-thick</v-icon></v-btn
                  >-->

            <div
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'position: absolute; right: 9px'
                  : 'position: absolute; right: 32px'
              "
              icon
            >
              <v-row style="margin-top: -2px; height: 20px">
                <v-btn
                  v-show="item.order_by > 1"
                  style="height: 20px"
                  icon
                  @click="moveUp(item)"
                  ><v-icon small>mdi-arrow-up-thick</v-icon></v-btn
                >
              </v-row>
              <v-row style="height: 20px">
                <v-btn
                  v-show="item.order_by < total"
                  style="height: 20px"
                  icon
                  @click="moveDown(item)"
                  ><v-icon small>mdi-arrow-down-thick</v-icon></v-btn
                >
              </v-row>
            </div>
          </v-row>
        </template>
        <v-list>
          <v-list-item
            class="cusList"
            @click="
              reserva({
                reserva: { id: item.id },
                action: 'save',
              })
            "
          >
            <v-list-item-icon>
              <v-icon small style="margin-top: -6px !important"
                >$appointments</v-icon
              ></v-list-item-icon
            ><v-list-item-title>
              <span>{{ $t("boxes.reservar") }}</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="cusList"
            @click="
              $router.push({
                name: 'boxView',
                params: { id: item.id },
              })
            "
          >
            <v-list-item-icon>
              <v-icon small style="margin-top: -5px !important"
                >$eye</v-icon
              ></v-list-item-icon
            ><v-list-item-title>
              <span>{{
                $t("view", { name: $tc("box") })
              }}</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="cusList"
            @click="$refs[`timetable-${item.id}`].open()"
          >
            <v-list-item-icon>
              <v-icon small style="margin-top: -5px !important"
                >$clock</v-icon
              ></v-list-item-icon
            ><v-list-item-title>
              <span>{{
                $t("timetable", { name: $tc("box") })
              }}</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="cusList"
            @click="OPEN_FORM({ box: item, action: 'edit' })"
          >
            <v-list-item-icon>
              <v-icon small style="margin-top: -5px !important"
                >$editprofile</v-icon
              ></v-list-item-icon
            >
            <v-list-item-title>
              <span>{{
                $t("edit", { name: $tc("box") })
              }}</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="cusList" @click="delBox(item)">
            <v-list-item-icon>
              <v-icon small style="margin-top: -6px !important"
                >$delete</v-icon
              ></v-list-item-icon
            >
            <v-list-item-title>
              <span>{{
                $t("delete", { name: $tc("box") })
              }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:item_price="item">
      <span v-if="item.price != null">{{ $n(item.price, "currency") }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item_token_payment="item">
      <span v-if="item.token_payment != null">{{
        $n(item.token_payment, "currency")
      }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item_user="item">
      <span v-if="item.user != null"
        >{{ item.user.first_name }} {{ item.user.last_name }}</span
      ><span v-else>-</span>
    </template>
    <template v-slot:item_status="item">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-if="item.status != null"
            v-on="on"
            v-bind="attrs"
            :style="
              boxesHoursDay(item.id).percentage >= 0.5
                ? boxesHoursDay(item.id).percentage >= 0.75
                  ? boxesHoursDay(item.id).percentage == 1
                    ? 'color:red; cursor:pointer'
                    : 'color:orange; cursor:pointer'
                  : 'color:yellow; cursor:pointer'
                : 'cursor:pointer'
            "
            >{{ $n(boxesHoursDay(item.id).percentage, "percent") }} /
            {{ boxesHoursDay(item.id).hour }}h
          </span>
        </template>

        <span>
          {{ $n(boxesHoursDay(item.id).percentage, "percent") }}
          {{ $t("ocupation") }} / {{ boxesHoursDay(item.id).hour }}
          {{ $t("hours_available") }}
        </span>
      </v-tooltip>
    </template>

    <template v-slot:item_avatar="item">
      <v-avatar
        class="ml-2"
        color="primary"
        :size="40"
        style="
          margin: 0 auto;
          font-size: 25px;
          text-transform: uppercase;
          color: #272727;
          cursor: pointer;
        "
      >
        <span>{{ item.order_by }}</span>
      </v-avatar>
    </template>
  </base-data-table-cards>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "BoxesListStudio",
  data() {
    return {
      items: null,
      total: null,
      options: {},
      loading: false,
      statusList: [],
      headers_small: [
        { value: "avatar" },
        { value: "status" },
        { value: "token_payment" },
      ],
    };
  },
  mounted() {
    this.fetchBoxes();
    this.fetchStatusList();
  },
  watch: {
    dateState: {
      handler() {
        this.fetchStatusList();
      },
      deep: true,
    },
  },
  components: {
    EditBoxesTimetable: () =>
      import("@/components/user/timetable/Configuration"),
  },
  methods: {
    ...mapMutations("boxes", ["OPEN_FORM", "reserva"]),
    ...mapActions("boxes", ["getBoxes", "getTable", "orderBox", "deleteBox"]),

    fetchBoxes() {
      this.loading = true;
      this.getBoxes({
        pagination: this.options,
        filters: this.filters,
      }).then((boxes) => {
        //console.log(this.load);
        this.total = boxes.total;
        this.items = boxes.data;
        this.boxes = boxes.data;
        this.loading = false;
      });
    },
    fetchStatusList() {
      this.loading = true;

      this.getTable({
        date: this.dateState,
        studio: this.$store.state.boxes.studioId,
      }).then((boxes) => {
        this.statusList = boxes.box;

        this.loading = false;
      });
    },
    boxesHoursDay(box) {
      let n = this.statusList.find((element) => element.id == box);

      if (n) {
        let hs = n.boxes_tattooer.filter((x) => x.start_date == this.dateState);

        if (hs) {
          //console.log(hs);

          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == end) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = [];
          let s = 0;

          hs.filter((k) => {
            for (let i = 0; i < hours.length; i++) {
              if (hours[i] == k.start_time) {
                s = i;
              } else if (hours[i] == k.end_time) {
                span.push(i - s);
              }
            }
          });
          let p = null;
          for (let index = 0; index < span.length; index++) {
            p += span[index];
          }
          let percentage = p / 2 / (end - start);
          let hour = p / 2 - (end - start);
          //console.log(percentage);
          return { percentage: percentage, hour: Math.abs(hour) };
        }
      }
      return { percentage: null };
    },
    income(box) {
      return box.boxes_tattooer.reduce((acumulat_total, reserva) => {
        let acumulat_reserva_total = 0;
        if (reserva.appointments)
          acumulat_reserva_total = reserva.appointments.expenses.reduce(
            (acumulat_reserva, expense) => {
              if (expense.user_id === this.$store.state.auth.user.id)
                return acumulat_reserva + expense.cantidad;
              return acumulat_reserva;
            },
            0
          );
        console.log(reserva.appointments);

        return acumulat_total + acumulat_reserva_total;
      }, 0);
    },

    moveUp(value) {
      this.orderBox({ id: 1, order: value }).then((response) => {
        if (response) {
          this.errors.clear();
          this.fetchBoxes();
        }
      });
    },
    moveDown(value) {
      this.orderBox({ id: 0, order: value }).then((response) => {
        if (response) {
          this.errors.clear();
          this.fetchBoxes();
        }
      });
    },

    delBox(value) {
      this.$confirm(this.$t("are_you_sure_box"), "", "").then(() => {
        this.id_box = value.id;
        //console.log(this.id_box);
        this.deleteBox({
          id_box: this.id_box,
        }).then((response) => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("box_delete_success"), "", "");
            this.reload();
          }
        });
      });
    },
  },
  computed: {
    ...mapState("boxes", ["dateState"]),
    ...mapGetters("boxes", ["timeFrom", "timeTo"]),
    headers() {
      let array = [
        {
          text: this.$t("boxes.id"),
          align: "center",
          sortable: false,
          value: "order_by",
        },
        {
          text: this.$t("boxes.name"),
          align: "center",
          sortable: false,
          value: "name",
        },
      ];
      //payment active
      if (this.$store.getters["auth/getSetting"]("payment_box") == 1) {
        array.push({
          text: this.$t("boxes.token_payment"),
          align: "center",
          sortable: false,
          value: "token_payment",
        });
      }
      if (this.$store.getters["auth/getSetting"]("box_price") == 1) {
        array.push({
          text: this.$t("boxes.price"),
          align: "center",
          sortable: false,
          value: "price",
        });
      }

      array.push({
        text: this.$t("boxes.status"),
        align: "center",
        sortable: false,
        value: "status",
      });
      array.push({
        text: this.$t("boxes.income"),
        align: "center",
        sortable: false,
        value: "income",
      });
      //TATTOOER ASSOCIATE

      array.push({
        text: this.$t("boxes.tattooer_associate"),
        align: "center",
        sortable: false,
        value: "user",
      });

      array.push({
        text: this.$t("actions"),
        align: "center",
        sortable: false,
        value: "actions",
      });

      return array;
    },
  },
};
</script>
